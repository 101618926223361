<template>
  <NodeViewWrapper
    class="mention-link"
    as="span"
  >
    <NuxtLink
      :to="localePath({
        name: 'user-user',
        params: {
          user: props.node.attrs.id
        }
      })"
      inline
    >
      @{{ props.node.attrs.label }}
    </NuxtLink>
  </NodeViewWrapper>
</template>

<script setup lang="ts">
import { NodeViewWrapper } from '@tiptap/vue-3'

const localePath = useLocalePath()

// see https://stackoverflow.com/questions/72512237/how-to-import-nodeviewprops-object-from-tiptap-in-vue-3-script-setup
const props = defineProps({
  node: {
    type: Object,
    required: true
  },
  updateAttributes: {
    type: Function,
    required: true,
  }
})
</script>

<style scoped lang="postcss">
:deep(.router-link) {
  @apply text-primary font-semibold
}
</style>
