import { VueNodeViewRenderer } from "@tiptap/vue-3";
import MentionLink from "./MentionLink.vue";
import { Mention } from "@tiptap/extension-mention";
import usersSuggestion from "~/tiptap/mention/suggestion";

export const UserMention = Mention.extend({
  name: 'user-mention',

  addNodeView() {
    return VueNodeViewRenderer(MentionLink)
  },
}).configure({
  HTMLAttributes: {
    class: 'mention',
  },
  suggestion: usersSuggestion
})
