import {
  callOrReturn,
  type ExtendedRegExpMatchArray,
  InputRule,
  type InputRuleFinder,
  mergeAttributes,
  Node, nodePasteRule,
} from '@tiptap/core'
import * as twemoji from "twemoji";
import { NodeType } from "prosemirror-model";

// TODO find a better way to escape, maybe with 'find'
export function escapingNodeInputRule(config: {
  find: InputRuleFinder,
  type: NodeType,
  getAttributes?:
    | Record<string, any>
    | ((match: ExtendedRegExpMatchArray) => Record<string, any>)
    | false
    | null
  ,
}) {
  return new InputRule({
    find: config.find,
    handler: ({ state, range, match }) => {
      const attributes = callOrReturn(config.getAttributes, undefined, match)

      // escape
      if (attributes === false || attributes === null) {
        return null
      }

      const { tr } = state
      const start = range.from
      let end = range.to

      if (match[1]) {
        const offset = match[0].lastIndexOf(match[1])
        let matchStart = start + offset

        if (matchStart > end) {
          matchStart = end
        } else {
          end = matchStart + match[1].length
        }

        // insert last typed character
        const lastChar = match[0][match[0].length - 1]

        tr.insertText(lastChar, start + match[0].length - 1)

        // insert node from input rule
        tr.replaceWith(matchStart, end, config.type.create(attributes))
      } else if (match[0]) {
        tr.replaceWith(start, end, config.type.create(attributes))
      }
    },
  })
}

export const Twemoji = Node.create({
  name: 'twemoji',

  inline: true,

  draggable: false,

  group: 'inline',

  atom: true,

  addAttributes() {
    return {
      codePoint: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `img[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const codePoint = HTMLAttributes['codePoint']
    return [
      'img',
      mergeAttributes(
        { 'data-type': this.name },
        this.options.HTMLAttributes,
        HTMLAttributes,
        {
          'src': `https://cdnjs.cloudflare.com/ajax/libs/twemoji/15.1.0/72x72/${codePoint}.png`,
          'alt': twemoji.default.convert.fromCodePoint(codePoint),
          'class': 'emoji'
        },
      )
    ]
  },

  addInputRules() {
    return [
      escapingNodeInputRule({
        // for example '⛩'
        find: /[\u0f00-\uffff]+/g,
        type: this.type,
        getAttributes: match => {
          let codePoint = null as (string | null)
          twemoji.default.parse(match[0], (iconId) => {
            codePoint = iconId
            return false
          })
          return codePoint ? { codePoint } : false
        }
      })
    ]
  },

  addPasteRules() {
    return [
      nodePasteRule({
        // for example '⛩'
        find: /[\u0f00-\uffff]+/g,
        type: this.type,
        getAttributes: match => {
          let codePoint = null as (string | null)
          twemoji.default.parse(match[0], (iconId) => {
            codePoint = iconId
            return false
          })
          return codePoint ? { codePoint } : false
        }
      })
    ]
  },
})
